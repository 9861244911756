export default (theme) => {
  const {
    breakpoints,
  } = theme

  return {
    title: {
      fontWeight: 'bold',
      [breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
    },
    subtitle: {
      fontWeight: '400',
      marginBottom: '3.13rem',
      [breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '3.5rem',
      [breakpoints.down('xs')]: {
        marginBottom: '1.5rem',
      },
    },
    card: {
      width: '100%',
      maxWidth: '230px',
      boxShadow: 'none',
      [theme.breakpoints.down('md')]: {
        margin: '0.5rem',
      },
    },
    titleCard: {
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '1rem',
      [breakpoints.down('sm')]: {
        fontSize: '1.125rem',
      },
    },
    subtitleCard: {
      fontSize: '0.75rem',
      fontWeight: '500',
      textAlign: 'center',
      marginBottom: '1rem',
      [breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    icon: {
      margin: '0 auto 0.75rem',
    },
  }
}
