import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles'
import { StaticQuery, graphql } from 'gatsby'
import {
  Container,
  Grid,
  Typography,
} from '@material-ui/core'

import CardServices from './card'

import styles from './styles'

const useStyles = makeStyles(styles)

const DesignServices = ({ data }) => {
  const classes = useStyles()

  const socialMediaData = [
    {
      icon: getImage(data.icon1),
      maintext: 'Best team desain',
      submaintext: 'Desain Anda akan dibuat oleh team kami yang sudah profesional di bidang desain',
    },
    {
      icon: getImage(data.icon2),
      maintext: 'Custom design request',
      submaintext: 'Sampaikan pada kami keinginan desain Anda , Team kami yang akan membantu mengkonsep desain sesuai dengan keinginan Anda.',
    },
    {
      icon: getImage(data.icon3),
      maintext: 'Mendapatkan soft file desain',
      submaintext: 'File ini untuk mempermudah Anda jika ingin posting atau edit desainnya di kemudian hari.',
    },
  ]

  const websiteData = [
    {
      icon: getImage(data.icon1),
      maintext: 'Best team desain',
      submaintext: 'Desain Anda akan dibuat oleh team kami yang sudah profesional di bidang desain',
    },
    {
      icon: getImage(data.icon2),
      maintext: 'Custom design request',
      submaintext: 'Sampaikan pada kami keinginan desain Anda , Team kami yang akan membantu mengkonsep desain sesuai dengan keinginan Anda.',
    },
    {
      icon: getImage(data.icon4),
      maintext: 'Optimasi Pagespeed',
      submaintext: 'Kami memikirkan bagaimana website Anda dapat memberikan experience terbaik untuk pengunjung.',
    },
    {
      icon: getImage(data.icon5),
      maintext: 'Mobile Friendly',
      submaintext: 'Desain website kami mendukung tampilan mobile responsif sehingga memberi user experience yang baik untuk semua pengguna dari berbagai perangkat.',
    },
    {
      icon: getImage(data.icon6),
      maintext: 'SEO (Google) optimization',
      submaintext: 'Kami memikirkan bagaimana website Anda dapat berada di posisi teratas, atau halaman pertama hasil pencarian berdasarkan kata kunci tertentu yang ditargetkan.',
    },
    {
      icon: getImage(data.icon7),
      maintext: 'Hosting and SSL cert (https)',
      submaintext: 'Kami memikirkan bagaimana website Anda dapat menyimpan data dengan aman dan dapat diakses dengan cepat oleh user yang berada diwilayah bisnis anda.',
    },
  ]

  return (
    <>
      <Grid className={classes.main}>
        <Grid
          item
          sm={10}
          md={7}
          lg={4}
        >
          <Typography
            variant="h5"
            className={classes.maintext}
          >
            Jasa desain
          </Typography>
          <Typography className={classes.submaintext}>
            Merancang desain website perusahaan Anda dan
            membantu mengkomunikasikan promosi bisnis Anda di sosial media
          </Typography>
        </Grid>
      </Grid>
      <Container maxWidth="lg">
        <Typography
          variant="h5"
          className={classes.maintextcenter}
        >
          Keuntungan membuat desain melalui Majin
        </Typography>
        <Typography
          variant="h6"
          className={classes.submaintextcenter}
        >
          Sosial Media
        </Typography>
        <Grid
          container
          spacing={4}
          className={classes.item}
        >
          {socialMediaData.map((item) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                key={item.maintext}
              >
                <CardServices
                  icon={item.icon}
                  maintext={item.maintext}
                  submaintext={item.submaintext}
                />
              </Grid>
            )
          })}
        </Grid>
        <Typography
          variant="h6"
          className={classes.submaintextcenter}
        >
          Website
        </Typography>
        <Grid
          container
          spacing={4}
          className={classes.item}
        >
          {websiteData.map((item) => {
            return (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={4}
                key={item.maintext}
              >
                <CardServices
                  icon={item.icon}
                  maintext={item.maintext}
                  submaintext={item.submaintext}
                />
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </>
  )
}

export default function DesignImage(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          icon1: file(relativePath: { eq: "images/best-team-design.png" }) {
            childImageSharp {
              gatsbyImageData(width: 80)
            }
          }
          icon2: file(relativePath: { eq: "images/custom-design-request.png" }) {
            childImageSharp {
              gatsbyImageData(width: 80)
            }
          }
          icon3: file(relativePath: { eq: "images/soft-file.png" }) {
            childImageSharp {
              gatsbyImageData(width: 80)
            }
          }
          icon4: file(relativePath: { eq: "images/optimasi-page.png" }) {
            childImageSharp {
              gatsbyImageData(width: 80)
            }
          }
          icon5: file(relativePath: { eq: "images/mobile-friendly.png" }) {
            childImageSharp {
              gatsbyImageData(width: 80)
            }
          }
          icon6: file(relativePath: { eq: "images/SEO.png" }) {
            childImageSharp {
              gatsbyImageData(width: 80)
            }
          }
          icon7: file(relativePath: { eq: "images/hosting-&-ssl.png" }) {
            childImageSharp {
              gatsbyImageData(width: 80)
            }
          }
        }
      `}
      render={data => <DesignServices data={data} {...props} />}
    />
  )
}
