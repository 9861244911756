import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)
const ServiceOrder = ({ data }) => {
  const classes = useStyles()
  const orderData = [
    {
      img: getImage(data.order1),
      title: 'Pemesanan',
      subtitle: 'Pilih paket desain yang anda inginkan dan butuhkan, kemudian untuk detail desain anda dapat menghubungi kami.',
    },
    {
      img: getImage(data.order2),
      title: 'Pembayaran',
      subtitle: 'Lakukan pembayaran langsung / Dp sesuai kesepakatan dan desain akan kami proses sesuai pesanan anda.',
    },
    {
      img: getImage(data.order3),
      title: 'Proses desain',
      subtitle: 'Lakukan konfirmasi pembayaran dan kami akan lakukan proses desain sesuai request dan kebutuhan anda.',
    },
    {
      img: getImage(data.order4),
      title: 'Revisi & Finishing',
      subtitle: 'Setelah selesai akan kami kirimkan desain anda dan anda dapat juga meminta revisi sesuai kesepakatan.',
    },
  ]
  return (
    <Container maxWidth="lg">
      <Typography
        variant="h5"
        align="center"
        className={classes.title}
      >
        Cara pemesanan desain
      </Typography>
      <Typography
        variant="body2"
        align="center"
        className={classes.subtitle}
      >
        Pemesanan paket mudah dan praktis
      </Typography>
      <Grid
        container
        spacing={4}
        direction="row"
        className={classes.item}
      >
        {orderData.map((item) => {
          return (
            <Grid
              container
              item
              xs={12}
              sm={6}
              md={6}
              lg={3}
              justify="center"
              alignItems="center"
              key={item.title}
            >
              <Card
                className={classes.card}
              >
                <CardContent style={{ textAlign: 'center' }}>
                  <GatsbyImage
                    image={item.img}
                    alt={item.title}
                    className={classes.icon}
                  />
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.titleCard}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    align="center"
                    className={classes.subtitleCard}
                  >
                    {item.subtitle}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )
        })}
      </Grid>
    </Container>
  )
}
export default function ServicesOrder(props) {
  return (
    <StaticQuery
      query={graphql`
      query {
        order1: file(relativePath: { eq: "images/services_order_icon.png" }) {
          childImageSharp {
            gatsbyImageData(width: 104)
          }
        }
        order2: file(relativePath: { eq: "images/services_payment_icon.png" }) {
          childImageSharp {
            gatsbyImageData(width: 104)
          }
        }
        order3: file(relativePath: { eq: "images/services_design_icon.png" }) {
          childImageSharp {
            gatsbyImageData(width: 104)
          }
        }
        order4: file(relativePath: { eq: "images/services_finishing_icon.png" }) {
          childImageSharp {
            gatsbyImageData(width: 104)
          }
        }
      }
    `}
      render={data => <ServiceOrder data={data} {...props} />}
    />
  )
}
