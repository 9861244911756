import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles'
import {
  Container,
  Typography,
  Card,
  Grid,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const ServiceWeb = ({ data }) => {
  const classes = useStyles()
  const items = [
    {
      id: 'web-1',
      title: 'Situs Perusahaan',
      image: getImage(data.web1),
    },
    {
      id: 'web-2',
      title: 'Online Bakery',
      image: getImage(data.web2),
    },
    {
      id: 'web-3',
      title: 'Online Fashion',
      image: getImage(data.web3),
    },
  ]

  return (
    <Container
      maxWidth="lg"
    >
      <Typography
        variant="h5"
        className={classes.titleSection}
      >
        Referensi desain untuk website
      </Typography>
      <Typography
        variant="body2"
        className={classes.subTitleSection}
      >
        Berikut ini referensi tema desain website yang kami tawarkan kepada anda
      </Typography>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        className={classes.grid}
        spacing={3}
      >
        {items.map((item) => (
          <Grid
            item
            key={item.id}
          >
            <Card
              className={classes.card}
            >
              <GatsbyImage
                className={classes.cardImage}
                image={item.image}
                alt={item.title}
              />
            </Card>
            <Typography
              variant="subtitle1"
              align="center"
              className={classes.title}
            >
              {item.title}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default function ServicesWeb(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          web1: file(relativePath: { eq: "images/web-1.png" }) {
            childImageSharp {
              gatsbyImageData(width: 300)
            }
          }
          web2: file(relativePath: { eq: "images/web-2.png" }) {
            childImageSharp {
              gatsbyImageData(width: 300)
            }
          }
          web3: file(relativePath: { eq: "images/web-3.png" }) {
            childImageSharp {
              gatsbyImageData(width: 300)
            }
          }
        }
      `}
      render={data => <ServiceWeb data={data} {...props} />}
    />
  )
}
