export default (theme) => {
  return {
    titleSection: {
      color: theme.color.textPrimary,
      textAlign: 'center',
      marginTop: '4.375rem',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
    },
    subTitleSection: {
      color: theme.color.textSecondary,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    grid: {
      marginTop: '3.125rem',
      marginBottom: '3.125rem',
    },
    card: {
      width: '18.75rem',
      height: '26.875rem',
      borderRadius: '1.25rem',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
    cardImage: {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
      transform: 'translateY(0)',
      transition: '2s ease-out',
      '&:hover': {
        objectFit: 'cover',
        width: '100%',
        transform: 'translateY(calc(-100% + 26.875rem))',
        transition: '2s ease-out',
      },
    },
    title: {
      marginTop: '1.25rem',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
    },
  }
}
