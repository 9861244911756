export default (theme) => {
  const {
    breakpoints,
  } = theme

  return {
    section: {
      backgroundColor: theme.color.silver,
    },
    container: {
      paddingTop: '3.125rem',
    },
    titleSection: {
      textAlign: 'center',
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
    },
    subTitleSection: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    carousel: {
      margin: '3.125rem auto 0',
      paddingBottom: '3.125rem',
    },
    grid: {
      margin: '0 auto 1rem',
      width: '45rem',
      [breakpoints.down('sm')]: {
        width: '21.438rem',
      },
      [breakpoints.down('xs')]: {
        width: '17.5rem',
      },
    },
    imgSosmed: {
      width: '13.75rem',
      height: '29rem',
    },
    imgPost: {
      margin: '0 auto',
      width: '29rem',
      height: '29rem',
      [breakpoints.down('sm')]: {
        width: '21.438rem',
        height: '21.438rem',
      },
      [breakpoints.down('xs')]: {
        width: '17.5rem',
        height: '17.5rem',
      },
    },
  }
}
