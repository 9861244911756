export default (theme) => {
  const {
    breakpoints,
    color,
  } = theme

  return {
    main: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4rem 1rem',
      marginBottom: '3rem',
      backgroundColor: color.opaqueGrey,
    },
    maintext: {
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '1.3rem',
      color: color.pink,

      [breakpoints.down('xs')]: {
        fontSize: '1.25rem',
        marginBottom: '1rem',
      },
    },
    submaintext: {
      textAlign: 'center',
      fontWeight: '500',

      [breakpoints.down('xs')]: {
        fontSize: '0.88rem',
      },
    },
    maintextcenter: {
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '0.5rem',

      [breakpoints.down('xs')]: {
        fontSize: '1.25rem',
      },
    },
    submaintextcenter: {
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '3.13rem',

      [breakpoints.down('xs')]: {
        fontSize: '1rem',
        marginBottom: '2rem',
      },
    },
    item: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '3.5rem',

      [breakpoints.down('xs')]: {
        marginBottom: '1.5rem',
      },
    },
    card: {
      height: '16rem',
      background: color.grey,
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '1.25rem',
    },
    icon: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '0.75rem',
    },
    maintextcard: {
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '1rem',
    },
    submaintextcard: {
      fontSize: '0.75rem',
      textAlign: 'center',
      fontWeight: '500',
    },
  }
}
