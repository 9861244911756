import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { makeStyles } from '@material-ui/core/styles'
import Carousel from 'react-material-ui-carousel'
import {
  Typography,
  Grid,
  Container,
  useTheme,
  Hidden,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const ServiceSocmed = ({ data }) => {
  const classes = useStyles()
  const theme = useTheme()

  const items = [
    {
      id: 'sosmed-1',
      sosmed: getImage(data.sosmed1),
      post: getImage(data.post1),
    },
    {
      id: 'sosmed-2',
      sosmed: getImage(data.sosmed2),
      post: getImage(data.post2),
    },
    {
      id: 'sosmed-3',
      sosmed: getImage(data.sosmed3),
      post: getImage(data.post3),
    },
    {
      id: 'sosmed-4',
      sosmed: getImage(data.sosmed4),
      post: getImage(data.post4),
    },
    {
      id: 'sosmed-5',
      sosmed: getImage(data.sosmed5),
      post: getImage(data.post5),
    },
    {
      id: 'sosmed-6',
      sosmed: getImage(data.sosmed6),
      post: getImage(data.post6),
    },
  ]

  return (
    <div
      style={{
        backgroundColor: theme.color.silver,
      }}
    >
      <Container
        maxWidth="lg"
        className={classes.container}
      >
        <Grid
          container
          direction="column"
        >
          <Typography
            variant="h5"
            className={classes.titleSection}
          >
            Referensi desain untuk sosial media
          </Typography>
          <Typography
            variant="body2"
            className={classes.subTitleSection}
          >
            Berikut ini referensi desain sosial media yang kami tawarkan kepada
            anda
          </Typography>
        </Grid>
        <Carousel
          className={classes.carousel}
          activeIndicatorProps={{
            style: {
              color: theme.color.purple,
            } }}
          navButtonsAlwaysInvisible
        >
          {items.map(item => (
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.grid}
              key={item.id}
            >
              <Hidden smDown>
                <Grid
                  lg
                  md
                  item
                >
                  <GatsbyImage
                    className={classes.imgSosmed}
                    image={item.sosmed}
                    alt="sosmed"
                  />
                </Grid>
              </Hidden>
              <Grid
                lg
                md
                sm={12}
                xs={12}
                item
              >
                <GatsbyImage
                  className={classes.imgPost}
                  image={item.post}
                  alt="post"
                />
              </Grid>
            </Grid>
          ))}
        </Carousel>
      </Container>
    </div>
  )
}

export default function ServicesSocmed(props) {
  return (
    <StaticQuery
      query={graphql`
        query {
          sosmed1: file(relativePath: { eq: "images/sosmed-1.png" }) {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
          post1: file(relativePath: { eq: "images/post-1.png" }) {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
          sosmed2: file(relativePath: { eq: "images/sosmed-2.png" }) {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
          post2: file(relativePath: { eq: "images/post-2.png" }) {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
          sosmed3: file(relativePath: { eq: "images/sosmed-3.png" }) {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
          post3: file(relativePath: { eq: "images/post-3.png" }) {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
          sosmed4: file(relativePath: { eq: "images/sosmed-4.png" }) {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
          post4: file(relativePath: { eq: "images/post-4.png" }) {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
          sosmed5: file(relativePath: { eq: "images/sosmed-5.png" }) {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
          post5: file(relativePath: { eq: "images/post-5.png" }) {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
          sosmed6: file(relativePath: { eq: "images/sosmed-6.png" }) {
            childImageSharp {
              gatsbyImageData(width: 400)
            }
          }
          post6: file(relativePath: { eq: "images/post-6.png" }) {
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
        }
      `}
      render={data => <ServiceSocmed data={data} {...props} />}
    />
  )
}
