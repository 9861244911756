import React from 'react'
import { graphql } from 'gatsby'

import Page from '~/src/components/page'
import Seo from '~/src/components/seo'
import BackgroundServices from '~/src/components/background/services'
import MainPage from '~/src/components/services/design-services'
import ServicesWeb from '~/src/components/services/website'
import Pricing from '~/src/components/pricing'
import ContactUs from '~/src/components/contact'
import ServicesSocmed from '~/src/components/services/socmed'
import ServiceOrder from '~/src/components/services/order'

const ServicePage = ({ data }) => {
  return (
    <Page>
      <Seo
        site={data.site}
        title="MAJIN Services: Merancang desain website perusahaan Anda dan membantu mengkomunikasikan promosi bisnis Anda di sosial media"
        ogTitle="MAJIN Services: Merancang desain website perusahaan Anda dan membantu mengkomunikasikan promosi bisnis Anda di sosial media"
        description="Jasa desain dan rancang website profesional untuk perusahaan dan transformasi bisnis digital"
        canonical="https://majin.land/services/"
        ogImage="https://majin.land/images/post-3.png"
      />
      <BackgroundServices />
      <MainPage />
      <ServiceOrder />
      <Pricing />
      <ServicesSocmed />
      <ServicesWeb />
      <ContactUs />
    </Page>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`

export default ServicePage
