import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { GatsbyImage } from 'gatsby-plugin-image'
import {
  Typography,
  Card,
  CardContent,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const CardServices = (props) => {
  const classes = useStyles()

  return (
    <Card
      className={classes.card}
      elevation={0}
    >
      <CardContent style={{ textAlign: 'center' }}>
        <GatsbyImage
          image={props.icon}
          alt={props.maintext}
          className={classes.icon}
        />
        <Typography
          variant="subtitle2"
          className={classes.maintextcard}
        >
          {props.maintext}
        </Typography>
        <Typography className={classes.submaintextcard}>
          {props.submaintext}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default CardServices
